import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      {/* Add your privacy policy content here */}
    </div>
  );
};

export default PrivacyPolicy;
